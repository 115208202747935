import { format, parseISO } from "date-fns";
import _ from "lodash";
import { sleep } from "wait-promise";
import BagAPI from "../../api-services/bag";
import { PAYMENT_ID } from "../../api-services/constants";
import PaymentsOSAPI from "../../api-services/paymentsOSAPI";
import * as zeamsterAPI from "../../api-services/zeamsterAPI";
import ZoozAPI from "../../api-services/zoozAPI";
import { LOGIN_TYPES } from "../../components/LoginView";
import * as CONSTANTS from "../../utils/constants";
import { DEFAULT_ERROR, NOW } from "../../utils/constants";
import { setDescriptionForAddress } from "../../utils/geoLocation";
import getPickupTimesTranslatedInSelectForm from "../../utils/getPickupTimesTranslatedInSelectForm";
import { fillGiftsExpirationDate } from "../../utils/gifts";
import { getOrderParams } from "../../utils/location";
import { checkoutItemToMenuItemConfiguration, toConfiguredMenuItemOrderData } from "../../utils/menuItemOrderData";
import moment from "../../utils/moment-timezone-with-data";
import { withTimestamp } from "../asyncAction";
import { setMenuItemConfiguration } from "../menuItemsConfiguration/actions";
import { getBranchPickupTimes } from "../selectors";
import * as ACTION_TYPE from "./constants";

const businessId = process.env.GATSBY_BUSINESS_ID;
const SUPPORTED_PAYMENT_IDS = _.values(PAYMENT_ID);

const CREDIT_CARD_ERRORS = [393749, 197378];

const CHECKING_ORDER_STATUS_DELAY = 1000 * 60 * 5 // 5 min

const applyZipCodeIfNeeded = (creditCard, user) => {
  if (_.get(creditCard, "zipCode")) {
    console.log("creditCard", creditCard);
    return { billingAddress: { zipCode: creditCard.zipCode } };
  } else if (user && _.get(user, "paymentMethods.data[0].billingAddress")) {
    return {
      billingAddress: _.get(user, "paymentMethods.data[0].billingAddress"),
    };
  }
  return {};
};

const applyFieldsFromTokenResponse = (tokenResponse) => {
  if (
    _.get(tokenResponse, "paymentMethod.first_six_digits") &&
    _.get(tokenResponse, "paymentMethod.last_four_digits")
  ) {
    return {
      firstSixDigits: _.get(tokenResponse, "paymentMethod.first_six_digits"),
      lastFourDigits: _.get(tokenResponse, "paymentMethod.last_four_digits"),
    };
  }
  return {};
};

const toLeanOrder = (orderToLean) => {
  const order = _.cloneDeep(orderToLean);

  _.forEach(order.orderItems, (orderItem) => {
    _.forEach(
      orderItem.configuredMenuItemOrderData.orderItemComponents,
      (orderItemComponent) => {
        // delete orderItemComponent.orderItemComponentOptions;
        delete orderItemComponent.componentOptions;
      },
    );
  });
  return order;
};

const toLeanCheckoutResponse = (checkoutResponseToLean) => {
  const checkoutResponse = _.cloneDeep(checkoutResponseToLean);
  delete checkoutResponse.order.servingOption;
  _.forEach(checkoutResponse.order.orderItems, (orderItem) => {
    _.forEach(
      orderItem.configuredMenuItemOrderData.orderItemComponents,
      (orderItemComponent) => {
        // delete orderItemComponent.orderItemComponentOptions;
        delete orderItemComponent.componentOptions;
        delete orderItemComponent.posInnerMenuToSpecificPriceMap;
      },
    );
    _.forEach(
      orderItem.configuredMenuItemOrderData.orderItemComponents,
      (orderItemComponent) => {
        // delete orderItemComponent.orderItemComponentOptions;
        delete orderItemComponent.componentOptions;
        delete orderItemComponent.orderItemComponentOptions;
      },
    );
  });


  return checkoutResponse;
};

const humenanizeSpreedlyCardError = (cardErrorString) => {
  const trimmedError = _.trim(cardErrorString);
  switch (trimmedError) {
    case "TRANS DENIED":
      return "Credit card error: Transaction denied";
    case "DECLINED":
      return "Credit card error: Transaction declined";
    default:
      return `Credit Card Error: Transaction failed [${trimmedError}]`;
  }
};

const fillCurrencyInGiftCardPaymentRequest = (
  externalGiftCardPayments,
  currency,
) => {
  return _.map(externalGiftCardPayments, (giftCard) => {
    return {
      ...giftCard,
      chargeAmount: { ...giftCard.chargeAmount, currency },
    };
  });
};

export const setServingOption = (servingOption) => {
  return withTimestamp({
    type: ACTION_TYPE.SET_SERVING_OPTION,
    payload: { servingOption },
  });
};

export const startNewOrder = () => ({ type: ACTION_TYPE.START_NEW_ORDER });

export const resetReorder = () => ({ type: ACTION_TYPE.RESET_REORDER });

export const addItem = (item) =>
  withTimestamp({
    type: ACTION_TYPE.ADD_TO_ORDER,
    payload: { item },
  });

export const addItems = (items) =>
  withTimestamp({
    type: ACTION_TYPE.ADD_ITEMS_TO_ORDER,
    payload: { items },
  });

export const removeItem = (itemIndex) =>
  withTimestamp({
    type: ACTION_TYPE.REMOVE_FROM_ORDER,
    payload: { itemIndex },
  });

export const removeAllItems = () =>
  withTimestamp({
    type: ACTION_TYPE.REMOVE_ALL_FROM_ORDER,
  });

export const updateItemQuantity = ({ itemIndex, count }) =>
  withTimestamp({
    type: ACTION_TYPE.UPDATE_ITEM_QUANTITY,
    payload: { itemIndex, count },
  });

export const startOrderItemEdit = (itemIndex, itemId, menuData) => (
  dispatch,
  getState,
) => {
  const { order } = getState();

  const {
    checkoutResponse: { order: checkoutOrder },
  } = order;

  const item = checkoutOrder.orderItems[itemIndex];

  const menuItemConfiuration = checkoutItemToMenuItemConfiguration(
    item,
    menuData,
  );

  dispatch(setMenuItemConfiguration(itemId, menuItemConfiuration));
  dispatch({
    type: ACTION_TYPE.START_ORDER_ITEM_EDIT,
    payload: { itemIndex, itemId },
  });
};

export const applyOrderItemEdit = (itemIndex, item) =>
  withTimestamp({
    type: ACTION_TYPE.APPLY_ORDER_ITEM_EDIT,
    payload: { itemIndex, item },
  });

export const cancelOrderItemEdit = () => ({
  type: ACTION_TYPE.CANCEL_ORDER_ITEM_EDIT,
});

export const setDeliveryAddress = (deliveryAddress, options = {}) =>
  withTimestamp({
    type: ACTION_TYPE.SET_ORDER_DELIVERY_ADDRESS,
    payload: { deliveryAddress, options },
  });

export const setOrderTip = (orderTip) => ({
  type: ACTION_TYPE.SET_ORDER_TIP_AMOUNT,
  payload: { orderTip },
});

export const setGiftCardsToRedeem = (giftCards) => ({
  type: ACTION_TYPE.SET_GIFT_CARDS_TO_REDEEM,
  payload: { giftCards },
});

export const setDeliveryTipPercentage = (deliveryTipPercentage) => ({
  type: ACTION_TYPE.SET_DELIVERY_TIP_PERCENTAGE,
  payload: { deliveryTipPercentage },
});

export const setDeliveryTipCustomValue = (deliveryTipCustomValue) => ({
  type: ACTION_TYPE.SET_DELIVERY_TIP_CUSTOM_VALUE,
  payload: { deliveryTipCustomValue },
});

export const setServingOptionTipPercentage = (servingOptionTipPercentage) => ({
  type: ACTION_TYPE.SET_SERVING_OPTION_TIP_PERCENTAGE,
  payload: { servingOptionTipPercentage },
});

export const setChosenPaymentType = (chosenPaymentType) => ({
  type: ACTION_TYPE.SET_CHOSEN_PAYMENT_TYPE,
  payload: { chosenPaymentType },
});

export const setServingOptionTipCustomValue = (
  servingOptionTipCustomValue,
) => ({
  type: ACTION_TYPE.SET_SERVING_OPTION_TIP_CUSTOM_VALUE,
  payload: { servingOptionTipCustomValue },
});

export const resetDeliveryAddress = () => ({
  type: ACTION_TYPE.RESET_ORDER_DELIVERY_ADDRESS,
});

export const setBranchIdAndNextAvailableTime = (
  branchId,
  firstAvailableDate,
  props,
) => (dispatch, getState) => {
  const pickupTimes = getBranchPickupTimes(
    getState(),
    props,
    branchId,
    firstAvailableDate,
  );
  const intialPickupTime = _.get(
    getPickupTimesTranslatedInSelectForm({ pickupTimes }),
    "[0].value",
  );

  dispatch(
    withTimestamp({
      type: ACTION_TYPE.SET_BRANCH_ID_AND_NEXT_AVAILABLE_TIME,
      payload: {
        branchId,
        ...(_.includes(intialPickupTime, NOW)
          ? { pickupTime: NOW }
          : { futureServingTime: firstAvailableDate }),
      },
    }),
  );
};
export const setUserSelectedLocationAndServingOption = ({
  servingOption,
  locationId,
  firstAvailableDate,
  userAddress,
  defaultTip,
  props,
}) => (dispatch, getState) => {
  const pickupTimes = getBranchPickupTimes(
    getState(),
    props,
    locationId,
    firstAvailableDate,
  );
  const intialPickupTime = _.get(pickupTimes, "data[0].value");

  dispatch(
    withTimestamp({
      type: ACTION_TYPE.SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION,
      payload: {
        locationId,
        ...(_.includes(intialPickupTime, NOW)
          ? { pickupTime: NOW }
          : { futureServingTime: firstAvailableDate }),
        servingOption,
        userAddress,
        defaultTip,
      },
    }),
  );
};

export const resetBranchId = () => ({
  type: ACTION_TYPE.RESET_ORDER_BRANCH_ID,
});

export const setFixedRemark = (fixedRemark) => ({
  type: ACTION_TYPE.SET_ORDER_FIXED_REMARKS,
  payload: { fixedRemark },
});

const buildFutureServingTime = ({
  pickupTime,
  timeZoneStr,
  futureServingTime,
}) => {
  const time = _.split(
    _.replace(_.replace(pickupTime, "am", ""), "pm", ""),
    ":",
  );
  const pastMidday =
    (_.includes(pickupTime, "pm") && _.toInteger(time[0]) < 12) ||
    (_.includes(pickupTime, "am") && _.toInteger(time[0]) == 12);
  const hour = (_.toInteger(time[0]) + (pastMidday ? 12 : 0)) % 24;
  const minute = _.toInteger(time[1]);
  return moment(moment
    .tz(moment(futureServingTime || new Date()), timeZoneStr)
    .hours(hour)
    .minutes(minute)
    .seconds(0)
    .toDate()).format();
};

export const setPickupTime = ({ pickupTime, timeZoneStr }) => (
  dispatch,
  getState,
) => {
  const { order } = getState();
  if (_.includes(pickupTime, NOW)) {
    return dispatch(
      withTimestamp({
        type: ACTION_TYPE.SET_PICKUP_TIME,
        payload: { pickupTime: NOW, futureServingTime: null },
      }),
    );
  }

  return dispatch(
    withTimestamp({
      type: ACTION_TYPE.SET_PICKUP_TIME,
      payload: {
        pickupTime,
        futureServingTime: buildFutureServingTime({
          pickupTime,
          timeZoneStr,
          futureServingTime: order.futureServingTime || moment().format(),
        }),
      },
    }),
  );
};

export const setFutureServingTime = ({
  futureServingDate,
  timeZoneStr,
  branchId,
  props,
}) => (dispatch, getState) => {
  const { order } = getState();

  const pickupTime =
    order.pickupTime ||
    _.get(
      getBranchPickupTimes(getState(), props, branchId, futureServingDate),
      "data[0].value",
    );

  if (_.includes(pickupTime, NOW)) {
    return dispatch(
      withTimestamp({
        type: ACTION_TYPE.SET_FUTURE_SERVING_TIME,
        payload: {
          futureServingTime: moment
            .tz(moment(futureServingDate), timeZoneStr)
            .toDate(),
        },
      }),
    );
  }

  dispatch(
    withTimestamp({
      type: ACTION_TYPE.SET_FUTURE_SERVING_TIME,
      payload: {
        futureServingTime: buildFutureServingTime({
          pickupTime,
          timeZoneStr,
          futureServingTime: futureServingDate,
        }),
      },
    }),
  );
};

export const resetEditItemMode = () => (dispatch, getState) => {
  const {
    order: {
      editingItem: { itemId, itemIndex },
    },
  } = getState();
  const menuItemConfiguration = {
    ...getState().order.orderItems[itemIndex].menuItemConfiguration,
    remark: null,
  };
  dispatch(setMenuItemConfiguration(itemId, menuItemConfiguration));
  dispatch({ type: ACTION_TYPE.RESET_EDIT_ITEM_MODE });
};

export const editItemFromPayment = () => (dispatch, getState) =>
  dispatch({ type: ACTION_TYPE.EDIT_ITEM_FROM_PAYMENT });

const applyServingOptionCountRemarksAndUpsales = (
  servingOptions,
  { reorder, ...order },
) => {
  const servingOption =
    _.find(servingOptions, { type: order.servingOptionType }) ||
    servingOptions[0];
  const { id: servingOptionId } = servingOption;
  if (!servingOptionId) {
    console.error("servingOptionId missing");
  }

  const orderItems = _.map(order.orderItems, (orderItem) => {
    const {
      configuredMenuItemOrderData: {
        remark,
        count,
        sourceItem,
        ...configuredMenuItemOrderData
      },
    } = orderItem;
    // const servingOptions = _.map(
    //   configuredMenuItemOrderData.menuItemServingOptions.servingOptions,
    //   (option) => ({
    //     ...option,
    //     servingOption: _.omit(option.servingOption, [
    //       "timeFrames",
    //       "availableNow",
    //     ]),
    //   }),
    // );

    return {
      remark: reorder ? orderItem.remark : remark,
      count: count || 1,
      ...(orderItem.addedFromUpsale && {
        addedFromUpsale: orderItem.addedFromUpsale,
      }),
      configuredMenuItemOrderData: {
        ..._.omit(configuredMenuItemOrderData, "count"),
        menuItemServingOptions: {
          ..._.omit(
            configuredMenuItemOrderData.menuItemServingOptions,
            "servingOptions",
          ),
          selectedServingOption: {
            ...configuredMenuItemOrderData.menuItemServingOptions
              .selectedServingOption,
            selectedServingOptionId: servingOptionId,
          },
        },
      },
    };
  });

  const isDelayedPickup =
    !_.isEmpty(order.pickupTime) &&
    !_.includes(order.pickupTime, CONSTANTS.NOW);

  return {
    orderItems,
    branchId: order.branchId,
    pickupTime: isDelayedPickup
      ? `${format(parseISO(order.futureServingTime), "MMM dd, yyyy")} ${order.pickupTime}`
      : order.pickupTime || CONSTANTS.NOW,
    fixedRemarks: order.fixedRemarks,
    ...(isDelayedPickup && { futureServingTime: order.futureServingTime }),
    executePayment: order.executePayment,
  };
};

export const saveOrderIfNeeded = (servingOptions, { force } = {}) => (
  dispatch,
  getState,
) => {
  const { user, order: unsantizedOrder } = getState();
  const {
    deliveryAddress,
    orderItems,
    saveOrderError,
    lastChangeTimestamp,
    lastSaveTimestamp,
    savingRequestsTimestamps,
    promoCodes,
    servingOption,
    tableCode,
  } = unsantizedOrder;

  const orderDetails = applyServingOptionCountRemarksAndUpsales(
    servingOptions,
    unsantizedOrder,
  );

  // Need More Testing: we should keep order id if user did not logged out or logged in during order. (1/2)
  // const orderId = _.get(getState(), 'order.checkoutResponse.order.id');

  if (_.isEmpty(orderItems) && !promoCodes) {
    return;
  }
  if (
    ((saveOrderError) || // Prevent failure loop unless forced by user e.g. click Try Again
    lastChangeTimestamp <= lastSaveTimestamp ||
    _.includes(savingRequestsTimestamps, lastChangeTimestamp)) && !force
  ) {
    return;
  }
  // Need More Testing: we should keep order id if user did not logged out or logged in during order. (2/2)
  // const order = { ...(orderId && { id: orderId }), ...orderDetails, deliveryAddress };
  
  const order = {
    tableCode,
    promoCodes,
    ...toLeanOrder(orderDetails),
    ...(_.get(servingOption, "needsAddress") && { deliveryAddress }),
  };

  const saveOrderRequestTimestamp = lastChangeTimestamp;

  dispatch(
    withTimestamp(
      {
        type: ACTION_TYPE.SAVE_ORDER.STARTED,
        payload: { order },
      },
      saveOrderRequestTimestamp,
    ),
  );

  const bagAPI = new BagAPI(user);

  bagAPI
    .updateOrderAndBeginPayment(order)
    .then((checkoutResponse) => {
      if (checkoutResponse.creationStatus) {
        console.log("Error in update order:", checkoutResponse.creationStatus);
      }
      console.log("Order:", order.orderItems);
      console.log(
        "Charge Card Balance",
        _.get(checkoutResponse, "orderUserBalanceDetails"),
      );
      dispatch(
        withTimestamp(
          {
            type: ACTION_TYPE.SAVE_ORDER.SUCCESS,
            payload: {
              checkoutResponse: {
                ...checkoutResponse,
                couponBatchesResponse: checkoutResponse.couponBatchesResponse && {
                  ...checkoutResponse.couponBatchesResponse,
                  couponInstances: fillGiftsExpirationDate(
                    checkoutResponse.couponBatchesResponse.couponInstances,
                  ),
                },
              },
              isSignUpOnPayment: order.executePayment,
            },        
          },
          saveOrderRequestTimestamp,
        ),
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(
        withTimestamp(
          {
            type: ACTION_TYPE.SAVE_ORDER.FAILED,
            payload: { error },
          },
          saveOrderRequestTimestamp,
        ),
      );
    });
};

export const setCouponsChecked = ({ checked }) => ({
  type: ACTION_TYPE.SET_COUPONS_CHECKED,
  payload: { checked },
});

export const setChargeCardDeselected = (isChargeCardDeselected) => ({
  type: ACTION_TYPE.SET_CHARGE_CARD_DESELECTED,
  payload: { isChargeCardDeselected },
});

export const applyParamsToOrder = (params) => ({
  type: ACTION_TYPE.APPLY_PARAMS_TO_ORDER,
  payload: getOrderParams(params),
});

export const setOrderForReorder = (
  order,
  firstAvailableDate,
  menuData = null,
) => {
  order.orderItems = _.map(order.orderItems, (orderItem) => {
    const sizeOptionId = orderItem.configuredMenuItemOrderData.menuItemId;
    const getMenuItemWithSizeOption = (items) =>
      _.find(items, (item) => _.includes(item.sizeOptionsIds, sizeOptionId));

    const menuDataCategoryWithSizeOption = _.find(
      _.filter(menuData, (menuDataCategory) => menuDataCategory.holdsItems),
      (menuDataCategoryWithSizeOptions) =>
        getMenuItemWithSizeOption(menuDataCategoryWithSizeOptions.items),
    );
    if (!_.isEmpty(menuDataCategoryWithSizeOption)) {
      const sourceItem = getMenuItemWithSizeOption(
        menuDataCategoryWithSizeOption.items,
      );
      orderItem.configuredMenuItemOrderData.sourceItem = sourceItem;
      _.set(orderItem, "menuItemConfiguration.selectedSizeId", sizeOptionId);
      orderItem.sourceItem = sourceItem;
    }
    const configuredMenuItemOrderData = {
      ...orderItem.configuredMenuItemOrderData,
      count: orderItem.count,
      remark: orderItem.remark,
    };
    return { ...orderItem, configuredMenuItemOrderData };
  });

  order.deliveryAddress = order.deliveryAddress
    ? {
        ...order.deliveryAddress,
        description:
          order.deliveryAddress.description ||
          setDescriptionForAddress(order.deliveryAddress),
      }
    : null;

  return withTimestamp({
    type: ACTION_TYPE.START_REORDER,
    payload: { order, firstAvailableDate },
  });
};

const getPaymentErrorAction = (paymentResult, actionFailureType) => {
  const error = paymentResult.message || DEFAULT_ERROR;

  console.error(error);

  if (
    _.startsWith(error, "There was a problem with your method of payment") ||
    _.startsWith(error, "Sorry! It looks like")
  ) {
    return {
      type: actionFailureType,
      payload: { error },
    };
  }
  if (_.startsWith(error, "Credit card error:")) {
    const [cardErrorPrefix, cardErrorString] = error.split(":");
    return {
      type: actionFailureType,
      payload: { error: humenanizeSpreedlyCardError(cardErrorString) },
    };
  }
  if (
    error ===
    "Failed [provider_request_error: null] (The request sent to the provider is invalid.)"
  ) {
    return {
      type: actionFailureType,
      payload: { error: "We are sorry, this card type is not supported" },
    };
  }
  if (
    error ===
    "Failed [payment_method_error: null] (The card's 'cvv' security code is invalid.)"
  ) {
    return {
      type: actionFailureType,
      payload: { error: "The card's security code (CVV) is incorrect" },
    };
  }
  if (
    error ===
      "Failed [payment_method_declined: null] (The transaction was declined by the Issuing bank.)" ||
    error ===
      "Failed [provider_error: null] (Something went wrong on the provider's side.)"
  ) {
    return {
      type: actionFailureType,
      payload: { error: "The transaction was declined by the Issuing bank" },
    };
  }
  if (_.isString(error) && error.indexOf("time as it is too soon") !== -1) {
    return {
      type: actionFailureType,
      payload: { error },
    };
  }
  if (_.isString(error) && !_.isEmpty(error)) {
    return {
      type: actionFailureType,
      payload: { error },
    };
  }

  console.log("general error, trying to check order status...");
  return {
    type: actionFailureType,
    payload: { error, shouldCheckOrderStatus: true },
  };
};

export const approveCashPayment = ({
  customerDetails,
  occiChargeAmount,
  cashAmount,
  currency,
  externalGiftCardPayments,
  recaptchaToken
}) => async (dispatch, getState) => {
  const { order, user } = getState();
  console.log("approveCashPayment");
  const cashPaymentAmount = externalGiftCardPayments
    ? cashAmount -
      _.sumBy(externalGiftCardPayments, (giftCard) =>
        _.get(giftCard, "chargeAmount.amount"),
      )
    : cashAmount;

  dispatch({ type: ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.STARTED });
  const bagAPI = new BagAPI(user);
  try {
    const paymentResult = await bagAPI.approveCashPayment({
      recaptchaToken,
      orderId: _.get(order, "checkoutResponse.order.id"),
      order: {
        ...toLeanCheckoutResponse(order.checkoutResponse).order,
        fixedRemarks: _.get(order, "fixedRemarks"),
        mandatoryTextFields: _.get(order, "mandatoryTextFields"),
        userDetails: {
          name: customerDetails.fullName,
          email: customerDetails.email,
          phonenumber: customerDetails.phoneNumber,
        },
        orderTip: order.deliveryAddress ? null : order.orderTip,
        deliveryAddress: order.deliveryAddress,
      },
      ...(user.loggedIn &&
        occiChargeAmount > 0 && {
          occiChargeAmount: { currency, amount: occiChargeAmount },
        }),
      userId: user.loggedIn ? user.userId : customerDetails.phoneNumber,
      couponsBatchIndex: order.couponsBatchIndex,
      emailAddress: customerDetails.email,
      ...(user.loggedIn && {
        cashAmount: { currency, amount: cashPaymentAmount },
        externalGiftCardPaymentRequests: fillCurrencyInGiftCardPaymentRequest(
          externalGiftCardPayments,
          currency,
        ),
      }),
    });

    if (!paymentResult) {
      console.error(
        DEFAULT_ERROR,
        "No payment result returned for approveCashPayment",
      );
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.FAILED,
        payload: { error: DEFAULT_ERROR },
      });
    }
    if (paymentResult.status === 1) {
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.SUCCESS,
        payload: { paymentResult },
      });
    }
    return dispatch(
      getPaymentErrorAction(
        paymentResult,
        ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.FAILED,
      ),
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: ACTION_TYPE.ORDER_APPROVE_CASH_PAYMENT.FAILED,
      payload: {
        error,
        shouldCheckOrderStatus: true,
        checkingOrderStatusDeadline: Date.now() + CHECKING_ORDER_STATUS_DELAY,
      },
    });
  }
};

export const approveFreeOrder = ({
  customerDetails,
  occiChargeAmount,
  externalGiftCardPayments,
  currency,
}) => async (dispatch, getState) => {
  console.log("approveFreeOrder");
  const { user, order } = getState();

  dispatch({ type: ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.STARTED });

  const bagAPI = new BagAPI(user);
  const { email, fullName } = customerDetails;

  try {
    const paymentResult = await bagAPI.approveCreditCardPayment({
      emailAddress: email,
      orderId: _.get(order, "checkoutResponse.order.id"),
      order: {
        ...toLeanCheckoutResponse(order.checkoutResponse).order,
        fixedRemarks: _.get(order, "fixedRemarks"),
        mandatoryTextFields: _.get(order, "mandatoryTextFields"),
        userDetails: {
          name: fullName,
          email,
          phonenumber: customerDetails.phoneNumber,
        },
        orderTip: order.deliveryAddress ? null : order.orderTip,
        deliveryAddress: order.deliveryAddress,
      },
      ...(user.loggedIn &&
        occiChargeAmount > 0 && {
          occiChargeAmount: { currency, amount: occiChargeAmount },
        }),
      ...(user.loggedIn &&
        externalGiftCardPayments && {
          externalGiftCardPaymentRequests: fillCurrencyInGiftCardPaymentRequest(
            externalGiftCardPayments,
            currency,
          ),
        }),
      userId: user.userId,
      couponsBatchIndex: order.couponsBatchIndex,
    });

    if (!paymentResult) {
      console.error(DEFAULT_ERROR, "No payment result for applyPayment");
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.FAILED,
        payload: { error: DEFAULT_ERROR },
      });
    }
    if (paymentResult.status === 1) {
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.SUCCESS,
        payload: { paymentResult },
      });
    }
    return dispatch(
      getPaymentErrorAction(
        paymentResult,
        ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.FAILED,
      ),
    );
  } catch (error) {
    console.log(error);
    const errorMessage = typeof error === "string" ? error : DEFAULT_ERROR;

    console.error(errorMessage);
    dispatch({
      type: ACTION_TYPE.ORDER_APPROVE_FREE_ORDER.FAILED,
      payload: {
        error: errorMessage,
        shouldCheckOrderStatus: true,
        checkingOrderStatusDeadline: Date.now() + CHECKING_ORDER_STATUS_DELAY
      },
    });
  }
};

export const openPaymentAction = ({
  creditCard,
  user,
  paymentTypeIdentifier,
  currency,
  amount = 1.0,
  customerDetails,
  openForFuturePayment = false,
  isGiftCard = false,
  isSubscription = false,
  tipAmount = 0,
  purchaseEventId,
  branchId
}) => async (dispatch, getState) => {
  const {
    order: { checkoutResponse },
  } = getState();

  try {
    dispatch({ type: ACTION_TYPE.OPEN_PAYMENT.STARTED });
    const openPaymentResponse = await openPayment({
      creditCard,
      user,
      paymentTypeIdentifier,
      currency,
      amount,
      customerDetails,
      checkoutResponse,
      openForFuturePayment,
      tipAmount,
      purchaseEventId,
      branchId,
      isGiftCard,
      isSubscription
    });

    if (!openPaymentResponse || openPaymentResponse.errorDescription) {
      dispatch({ type: ACTION_TYPE.OPEN_PAYMENT.FAILED });
      console.error(
        `Error while open payment request: ${
          openPaymentResponse?.errorDescription
        }`
      );
    }

    console.log(openPaymentResponse);
    dispatch({
      type: ACTION_TYPE.OPEN_PAYMENT.SUCCESS,
      payload: { openPaymentResponse },
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPE.OPEN_PAYMENT.FAILED });
    console.error(error);
  }
};

const openPayment = ({
  creditCard,
  user,
  paymentTypeIdentifier,
  currency,
  amount = 1.0,
  customerDetails,
  checkoutResponse = {},
  checkDetails = {},
  openForFuturePayment,
  isGiftCard,
  isSubscription,
  tipAmount,
  purchaseEventId,
  branchId
}) => {
  const emailAddress = customerDetails
    ? customerDetails.email
    : user.userDetails.data.email;
  const fullName = customerDetails
    ? customerDetails.fullName
    : user.userDetails.data.name;
  const userId = user.loggedIn ? user.userId : customerDetails.phoneNumber

  const openPaymentRequest = {
    userId,
    amount: {
      amount,
      currency,
    },
    tipAmount,
    emailAddress,
    fullName,
    paymentType: paymentTypeIdentifier,
    phoneOs: "web",
    userMacAddress: user.uniqueIdentifier,
    businessId,
    ...applyZipCodeIfNeeded(creditCard, user),
    chargeCardInstanceId: user.loggedIn
      ? user.openChargeCardInstance.id
      : undefined,
    orderId: _.get(checkoutResponse, "order.id"),
    openForFuturePayment,
    isGiftCard,
    isSubscription,
    purchaseEventId,
    branchId: branchId || _.get(checkoutResponse, "order.branchId") || _.get(checkDetails, "check.branchId"),
  };

  const bagAPI = new BagAPI(user);

  return bagAPI.openPayment(openPaymentRequest);
};

export const addPaymentMethod = ({
  creditCard,
  user,
  paymentTypeDetails,
  customerDetails,
  addPaymentMethodForCheckout,
  tokenResponse,
  openPaymentResponse,
  branchId : selectedBranchId
}) => async (dispatch, getState) => {
  const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
  if (creditCard || paymentTypeIdentifier === PAYMENT_ID.STRIPE) {
    const bagAPI = new BagAPI(user);
    const emailAddress = customerDetails
      ? customerDetails.email
      : user.userDetails.data.email;
    const fullName = customerDetails
      ? customerDetails.fullName
      : user.userDetails.data.name;
    console.log("addPaymentMethod");
    const {
      order: { checkoutResponse, branchId: branchIdFromOrder },
    } = getState();
    const branchId = selectedBranchId || branchIdFromOrder;
    dispatch({ type: ACTION_TYPE.ADD_PAYMENT_METHOD.STARTED });

    try {
      if (
        _.includes(
          [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
          paymentTypeIdentifier,
        )
      ) {
        console.log("paying with spreedly...");

        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });

        if (user.loggedIn) {
          const savePaymentMethodRequest = {
            paymentType: paymentTypeIdentifier,
            phoneOs: "web",
            userId: user.userId,
            branchId,
            ticket: tokenResponse.token,
            businessId,
            userMacAddress: user.uniqueIdentifier,
            chargeCardInstanceId: user.openChargeCardInstance.id,
            ...(tokenResponse && {
              lastFourDigits: tokenResponse.paymentMethod.last_four_digits,
              cardType: tokenResponse.paymentMethod.card_type,
              expirationDate: {
                month: tokenResponse.paymentMethod.month,
                year: tokenResponse.paymentMethod.year,
              },
            }),
          };

          const savePaymentMethodSpreedlyResponse = await bagAPI.savePaymentMethod(
            savePaymentMethodRequest,
          );
        }

        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              displayString: tokenResponse.paymentMethod.last_four_digits,
              token: tokenResponse.token,
              typeString: paymentTypeIdentifier,
              expirationMonth: tokenResponse.paymentMethod.month,
              expirationYear: tokenResponse.paymentMethod.year,
              openPaymentResponse
            },
            shouldSavePaymentMethod: true,
          },
        });

        return { ticket: tokenResponse.token, openPaymentResponse };
      } else if (paymentTypeIdentifier === PAYMENT_ID.ZOOZ) {
        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });

        if (openPaymentResponse.errorDescription) {
          console.error(
            `Error From Zooz: ${openPaymentResponse.errorDescription}`,
          );
        }

        const zoozAPI = new ZoozAPI(openPaymentResponse.paymentAppId);
        await zoozAPI.init();

        const addPaymentMethodRequest = {
          command: "addPaymentMethod",
          paymentToken: openPaymentResponse.paymentToken,
          email: emailAddress,
          paymentMethod: {
            paymentMethodType: "CreditCard",
            paymentMethodDetails: {
              month: `${_.padStart(creditCard.month, 2, "0")}`,
              year: `${creditCard.year}`,
              cardNumber: creditCard.cardNumber,
              cvvNumber: creditCard.cvv,
              userIdNumber: creditCard.userIdNumber,
              cardHolderName: fullName,
            },
            configuration: { rememberPaymentMethod: user.loggedIn },
          },
        };

        const addPaymentResponse = await zoozAPI.addPaymentMethod(
          addPaymentMethodRequest,
        );
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              displayString: addPaymentResponse.lastFourDigits,
              token: addPaymentResponse.paymentMethodToken,
              typeString: addPaymentResponse.paymentMethodType,
              expirationMonth: addPaymentResponse.expirationMonth,
              expirationYear: addPaymentResponse.expirationYear,
              openPaymentResponse
            },
            shouldSavePaymentMethod: user.loggedIn,
          },
        });
        return {
          token: addPaymentResponse.paymentMethodToken,
          openPaymentResponse,
        };
      } else if (paymentTypeIdentifier === PAYMENT_ID.ZEAMSTER) {
        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });
        let ticketRequest = {};
        if (addPaymentMethodForCheckout) {
          ticketRequest = {
            orderId: _.get(checkoutResponse, "order.id"),
            timestamp: openPaymentResponse.timestamp,
            paymentAppId: openPaymentResponse.paymentAppId,
            hash: openPaymentResponse.hash,
            branchId,
            cardHolderName: fullName,
            cardNumber: creditCard.cardNumber,
            cvv: creditCard.cvv,
            year: creditCard.year,
            month: creditCard.month,
          };
        } else {
          const hashRequest = {
            userId: user.userId,
            orderId: user.uniqueIdentifier,
            timestamp: new Date().getTime(),
            paymentType: paymentTypeIdentifier,
            phoneOs: "web",
            userMacAddress: user.uniqueIdentifier,
            businessId,
            chargeCardInstanceId: user.openChargeCardInstance
              ? user.openChargeCardInstance.id
              : undefined,
          };
          const hashResponse = await bagAPI.getHashForTicket(hashRequest);

          ticketRequest = {
            orderId: user.uniqueIdentifier,
            timestamp: hashRequest.timestamp,
            paymentAppId: hashResponse.paymentAppId,
            hash: hashResponse.hash,
            cardHolderName: fullName,
            cardNumber: creditCard.cardNumber,
            cvv: creditCard.cvv,
            year: creditCard.year,
            month: creditCard.month,
          };
        }

        const zeamsterTicketResponse = await zeamsterAPI.requestTicket(
          ticketRequest,
        );

        if (user.loggedIn) {
          const savePaymentMethodRequest = {
            paymentType: PAYMENT_ID.ZEAMSTER,
            phoneOs: "web",
            userId: user.userId,
            ticket: zeamsterTicketResponse.ticket,
            businessId,
            userMacAddress: user.uniqueIdentifier,
            chargeCardInstanceId: user.openChargeCardInstance.id,
          };
          const savePaymentMethodZeamsterResponse = await bagAPI.savePaymentMethod(
            savePaymentMethodRequest,
          );
        }
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              displayString: creditCard.cardNumber.slice(-4),
              token: zeamsterTicketResponse.ticket,
              typeString: paymentTypeIdentifier,
              expirationMonth: creditCard.month,
              expirationYear: creditCard.year,
              openPaymentResponse
            },
            shouldSavePaymentMethod: true,
          },
        });
        return { ticket: zeamsterTicketResponse.ticket, openPaymentResponse };
      } else if (paymentTypeIdentifier === PAYMENT_ID.PAYMENTS_OS) {
        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });
        const publicKey = _.get(paymentTypeDetails, "publicKey");

        const paymentsOSAPI = new PaymentsOSAPI(publicKey);

        const paymentsOSTokenResponse = await paymentsOSAPI.tokenize({
          token_type: "credit_card",
          holder_name: _.isEmpty(fullName) ? "Not Available" : fullName,
          expiration_date: `${_.padStart(creditCard.month, 2, "0")}-${
            creditCard.year
          }`,
          card_number: creditCard.cardNumber,
          ...(creditCard.zipCode && {
            billing_address: { zip_code: creditCard.zipCode },
          }),
        });

        if (user.loggedIn) {
          const savePaymentMethodRequest = {
            paymentType: PAYMENT_ID.PAYMENTS_OS,
            phoneOs: "web",
            userId: user.userId,
            branchId,
            emailAddress: _.get(user, "userDetails.data.email"),
            ticket: paymentsOSTokenResponse.token,
            businessId,
            userMacAddress: user.uniqueIdentifier,
            chargeCardInstanceId: user.openChargeCardInstance.id,
          };

          const savePaymentMethodResponse = await bagAPI.savePaymentMethod(
            savePaymentMethodRequest,
          );
        }
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              displayString: creditCard.cardNumber.slice(-4),
              token: paymentsOSTokenResponse.token,
              typeString: paymentTypeIdentifier,
              expirationMonth: creditCard.month,
              expirationYear: creditCard.year,
              openPaymentResponse
            },
            shouldSavePaymentMethod: true,
          },
        });
        return {
          ticket: paymentsOSTokenResponse.token,
          openPaymentResponse,
        };
      } else if (paymentTypeIdentifier === PAYMENT_ID.CARD_CONNECT) {
        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });
        if (user.loggedIn) {
          const savePaymentMethodRequest = {
            paymentType: PAYMENT_ID.CARD_CONNECT,
            phoneOs: "web",
            userId: user.userId,
            branchId,
            emailAddress: _.get(user, "userDetails.data.email"),
            ticket: tokenResponse.token,
            businessId,
            userMacAddress: user.uniqueIdentifier,
            chargeCardInstanceId: user.openChargeCardInstance.id,
            ...(creditCard.zipCode && {
              billingAddress: { zipCode: creditCard.zipCode },
            }),
            ...(tokenResponse && {
              lastFourDigits: tokenResponse.paymentMethod.last_four_digits,
              expirationDate: {
                month: tokenResponse.paymentMethod.month,
                year: tokenResponse.paymentMethod.year,
              },
            }),
          };

          const savePaymentMethodResponse = await bagAPI.savePaymentMethod(
            savePaymentMethodRequest,
          );
          if (
            savePaymentMethodResponse &&
            _.get(savePaymentMethodResponse, "response")
          ) {
            dispatch({
              type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
              payload: {
                addPaymentResponse: {
                  displayString: tokenResponse.paymentMethod.last_four_digits,
                  token: _.get(savePaymentMethodResponse, "response"),
                  typeString: paymentTypeIdentifier,
                  expirationMonth: creditCard.month,
                  expirationYear: creditCard.year,
                  openPaymentResponse
                },
                shouldSavePaymentMethod: true,
              },
            });

            return {
              token: _.get(savePaymentMethodResponse, "response"),
              openPaymentResponse,
            };
          }
        }
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              // displayString: creditCard.cardNumber.slice(-4),
              token: tokenResponse.token,
              typeString: paymentTypeIdentifier,
              // expirationMonth: creditCard.month,
              // expirationYear: creditCard.year,
              openPaymentResponse
            },
            shouldSavePaymentMethod: false,
          },
        });

        return {
          token: tokenResponse.token,
          openPaymentResponse,
        };
      } else if (paymentTypeIdentifier === PAYMENT_ID.STRIPE) {
        // const openPaymentResponse = await openPayment({
        //   creditCard,
        //   user,
        //   paymentTypeIdentifier,
        //   currency,
        //   amount,
        //   customerDetails,
        //   checkoutResponse,
        //   addPaymentMethodForCheckout,
        // });
        console.log(tokenResponse);
        if (user.loggedIn) {
          const savePaymentMethodRequest = {
            paymentType: PAYMENT_ID.STRIPE,
            phoneOs: "web",
            userId: user.userId,
            branchId,
            emailAddress: _.get(user, "userDetails.data.email"),
            ticket: tokenResponse.token,
            businessId,
            userMacAddress: user.uniqueIdentifier,
            chargeCardInstanceId: user.openChargeCardInstance.id,
            ...(creditCard && creditCard.zipCode && {
              billingAddress: { zipCode: creditCard.zipCode },
            }),
            ...(tokenResponse && {
              lastFourDigits: tokenResponse.last4,
              expirationDate: {
                month: tokenResponse.exp_month,
                year: tokenResponse.exp_year,
              },
            }),
          };

          const savePaymentMethodResponse = await bagAPI.savePaymentMethod(
            savePaymentMethodRequest,
          );
          if (
            savePaymentMethodResponse &&
            _.get(savePaymentMethodResponse, "response")
          ) {
            dispatch({
              type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
              payload: {
                addPaymentResponse: {
                  displayString: savePaymentMethodRequest.lastFourDigits,
                  token: _.get(savePaymentMethodResponse, "response"),
                  typeString: paymentTypeIdentifier,
                  expirationMonth: savePaymentMethodRequest.expirationDate.month,
                  expirationYear: savePaymentMethodRequest.expirationDate.year,
                  openPaymentResponse
                },
                shouldSavePaymentMethod: true,
              },
            });

            return {
              token: _.get(savePaymentMethodResponse, "response"),
              openPaymentResponse,
            };
          }
        }
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS,
          payload: {
            addPaymentResponse: {
              // displayString: creditCard.cardNumber.slice(-4),
              token: tokenResponse.token,
              typeString: paymentTypeIdentifier,
              // expirationMonth: creditCard.month,
              // expirationYear: creditCard.year,
              openPaymentResponse
            },
            shouldSavePaymentMethod: false,
          },
        });

        return {
          token: tokenResponse.token,
          openPaymentResponse,
        };
      }
    } catch (error) {
      console.log(error, "!!!!! error !!!!!");
      if (addPaymentMethodForCheckout) {
        throw {
          ...error,
          ...(_.includes(CREDIT_CARD_ERRORS, error.responseErrorCode) && {
            shouldNotCheckOrderStatus: true,
          }),
        };
      } else {
        console.log(error);
        const displayError = _.get(error, "errorMessage") || error;
        dispatch({
          type: ACTION_TYPE.ADD_PAYMENT_METHOD.FAILED,
          payload: {
            error: _.isString(displayError) ? displayError : DEFAULT_ERROR,
          },
        });
      }
    }
  }
};

const addPaymentMethodIfNeeded = ({
  creditCard,
  customerDetails,
  amount,
  user,
  checkoutResponse = {},
  checkDetails = {},
  currency,
  paymentTypeDetails,
  tokenResponse,
  cvv,
  chargeCard = false,
  isGiftCard = false,
  openPaymentResponse,
  selectedBranchId
}) => async (dispatch) => {
  console.log("andAddPaymentMethodIfNeeded");
  console.log(openPaymentResponse);
  try {
    const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");

    if (!_.isEmpty(creditCard) && paymentTypeIdentifier !== PAYMENT_ID.TOAST_HOSTED) {
      const addPaymentMethodResponse = await dispatch(
        addPaymentMethod({
          creditCard,
          user,
          paymentTypeDetails,
          customerDetails,
          addPaymentMethodForCheckout: true,
          tokenResponse,
          openPaymentResponse,
          ...(!selectedBranchId && checkoutResponse.order && { branchId: checkoutResponse.order.branchId }),
          ...(!selectedBranchId && checkDetails.check && { branchId: checkDetails.check.branchId }),
          ...(selectedBranchId && { branchId: selectedBranchId })
        }),
      );

      return {
        paymentType: paymentTypeIdentifier,
        phoneOs: "web",
        bundleId: addPaymentMethodResponse.openPaymentResponse.paymentAppId,
        userMacAddress: user.uniqueIdentifier,
        businessId,
        emailAddress: customerDetails.email,
        paymentId: addPaymentMethodResponse.openPaymentResponse.paymentId,
        paymentToken: addPaymentMethodResponse.openPaymentResponse.paymentToken,
        ...(!_.includes([PAYMENT_ID.ZEAMSTER], paymentTypeIdentifier) && {
          chosenPaymentMethodToken:
            addPaymentMethodResponse.token || addPaymentMethodResponse.ticket,
        }),
        ...(_.includes([PAYMENT_ID.ZEAMSTER], paymentTypeIdentifier) && {
          ticket: addPaymentMethodResponse.ticket,
        }),
        saveTicket:
          paymentTypeIdentifier === PAYMENT_ID.ZEAMSTER ||
          (paymentTypeIdentifier === PAYMENT_ID.CARD_CONNECT && user.loggedIn),
        ...(!_.includes(
          [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
          paymentTypeIdentifier,
        ) && {
          cvv: creditCard.cvv,
        }),
        amount: {
          amount,
          currency,
        },
        ...applyZipCodeIfNeeded(creditCard, user),
        ...applyFieldsFromTokenResponse(tokenResponse),
        userId: user.loggedIn ? user.userId : customerDetails.phoneNumber,
        isGiftCard,
        isOneTimePayment: !chargeCard,
        chargeCardInstanceId: isGiftCard
          ? _.get(user, "giftCardState.data.giftCardId")
          : user.loggedIn
          ? user.openChargeCardInstance.id
          : undefined,
          ...(!selectedBranchId && checkoutResponse.order && { branchId: checkoutResponse.order.branchId }),
          ...(!selectedBranchId && checkDetails.check && { branchId: checkDetails.check.branchId }),
          ...(selectedBranchId && { branchId: selectedBranchId })
      };
    } else {
      // const openPaymentRequest = {
      //   userId: user.loggedIn ? user.userId : customerDetails.phoneNumber,
      //   amount: {
      //     amount,
      //     currency,
      //   },
      //   emailAddress: customerDetails.email,
      //   fullName: customerDetails.fullName,
      //   paymentType: paymentTypeIdentifier,
      //   phoneOs: "web",
      //   userMacAddress: user.uniqueIdentifier,
      //   businessId,
      //   chargeCardInstanceId: user.loggedIn
      //     ? isGiftCard
      //       ? _.get(user, "giftCardState.data.giftCardId")
      //       : user.openChargeCardInstance.id
      //     : undefined,
      //   ...(checkoutResponse && {
      //     orderId: _.get(checkoutResponse, "order.id"),
      //     branchId: _.get(checkoutResponse, "order.branchId"),
      //   }),
      //   ...(checkDetails && { branchId: checkDetails.check.branchId }),
      // };

      // const openPaymentResponse = await bagAPI.openPayment(openPaymentRequest);

      return {
        paymentType: paymentTypeIdentifier,
        phoneOs: "web",
        bundleId: openPaymentResponse.paymentAppId,
        userMacAddress: user.uniqueIdentifier,
        businessId,
        emailAddress: customerDetails.email,
        paymentId: openPaymentResponse.paymentId,
        paymentToken: openPaymentResponse.paymentToken,
        ...(paymentTypeIdentifier === PAYMENT_ID.TOAST_HOSTED && tokenResponse),
        chosenPaymentMethodToken: _.get(user, "paymentMethods.data[0].token"),
        ...applyZipCodeIfNeeded(creditCard, user),
        ...applyFieldsFromTokenResponse(tokenResponse),
        saveTicket: false,
        cvv,
        amount: {
          amount,
          currency,
        },
        userId: user.loggedIn ? user.userId : customerDetails.phoneNumber,
        isGiftCard,
        isOneTimePayment: chargeCard ? false : true,
        chargeCardInstanceId: user.loggedIn
          ? isGiftCard
            ? _.get(user, "giftCardState.data.giftCardId")
            : user.openChargeCardInstance.id
          : undefined,
        ...(!selectedBranchId && checkoutResponse.order && { branchId: checkoutResponse.order.branchId }),
        ...(!selectedBranchId && checkDetails.check && { branchId: checkDetails.check.branchId }),
        ...(selectedBranchId && { branchId: selectedBranchId })
      };
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: ACTION_TYPE.ADD_PAYMENT_METHOD.FAILED,
      payload: { error },
    });
    throw error;
  }
};

export const payWithCreditCard = (
  amount,
  {
    creditCard,
    customerDetails,
    paymentTypeDetails,
    currency,
    cvv,
    occiChargeAmount = 0,
    tokenResponse,
    externalGiftCardPayments,
    recaptchaToken
  },
) => async (dispatch, getState) => {
  const {
    order: { checkoutResponse, couponsBatchIndex, deliveryAddress, orderTip, openPaymentTokens },
    user,
  } = getState();
  const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
  console.log("payWithCreditCard");
  if (
    !paymentTypeIdentifier ||
    !_.includes(SUPPORTED_PAYMENT_IDS, paymentTypeIdentifier)
  ) {
    const error = `trying to pay (using credit card) but with not supported paymentTypeIdentifier: ${paymentTypeIdentifier}`;
    console.error(error);
    dispatch({
      type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED,
      payload: { error },
    });
  }

  if (!checkoutResponse) {
    const error = "trying to pay (using credit card) before checking out";
    console.error(error);
    dispatch({
      type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED,
      payload: { error },
    });
    return;
  }

  dispatch({ type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.STARTED });
  const { loggedIn } = user;

  const bagAPI = new BagAPI(user);

  const { email, fullName } = customerDetails;

  try {
    const {
      order: {
        checkoutResponse,
        fixedRemarks,
        mandatoryTextFields,
        branchId,
        openPaymentTokens
      },
    } = getState();
    const { loggedIn } = user;

    const creditCardPaymentAmount = externalGiftCardPayments
      ? amount -
        _.sumBy(externalGiftCardPayments, (giftCard) =>
          _.get(giftCard, "chargeAmount.amount"),
        )
      : amount;

    //open payment
    const openPaymentResponse = paymentTypeIdentifier === PAYMENT_ID.TOAST_HOSTED ? openPaymentTokens : await openPayment({
      creditCard,
      user,
      paymentTypeIdentifier,
      currency,
      amount: creditCardPaymentAmount,
      customerDetails,
      checkoutResponse,
      branchId
    });


    //add payment method if needed
    const confirmPaymentRequest = await dispatch(
      addPaymentMethodIfNeeded({
        creditCard,
        customerDetails,
        amount: creditCardPaymentAmount,
        user,
        checkoutResponse,
        currency,
        paymentTypeDetails,
        cvv,
        tokenResponse,
        openPaymentResponse,
      }),
    );
    
    const paymentResult = await bagAPI.approveCreditCardPayment({
      ...(loggedIn && { emailAddress: email }),
      orderId: _.get(checkoutResponse, "order.id"),
      order: {
        ...toLeanCheckoutResponse(checkoutResponse).order,
        fixedRemarks,
        mandatoryTextFields,
        userDetails: {
          name: fullName,
          email,
          phonenumber: customerDetails.phoneNumber,
        },
        deliveryAddress,
        orderTip: deliveryAddress ? null : orderTip,
        ...(loggedIn && { userId: user.userId }),
      },
      recaptchaToken,
      userPhoneNumber: customerDetails.phoneNumber,
      userId: loggedIn ? user.userId : customerDetails.phoneNumber,
      couponsBatchIndex: getState().order.couponsBatchIndex,
      ...(loggedIn
        ? {
            otpPaymentRequests: [
              {
                ...confirmPaymentRequest,
                amount: {
                  amount: creditCardPaymentAmount,
                  currency: confirmPaymentRequest.amount.currency,
                },
              },
            ],
            externalGiftCardPaymentRequests: fillCurrencyInGiftCardPaymentRequest(
              externalGiftCardPayments,
              currency,
            ),
          }
        : { confirmPaymentRequest }),
      ...(loggedIn &&
        occiChargeAmount > 0 && {
          occiChargeAmount: { currency, amount: occiChargeAmount },
        }),
    });

    if (!paymentResult) {
      console.error(DEFAULT_ERROR, "No payment result for applyPayment");
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED,
        payload: { error: DEFAULT_ERROR },
      });
    }
    if (paymentResult.status === 1) {
      return dispatch({
        type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.SUCCESS,
        payload: { paymentResult },
      });
    }
    return dispatch(
      getPaymentErrorAction(
        paymentResult,
        ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED,
      ),
    );
  } catch (error) {
    const errorMessage =
      typeof error === "string" || !error.errorMessage
        ? error
        : error.errorMessage;
    console.error(errorMessage);
    const shouldCheckOrderStatus = !_.get(error, "shouldNotCheckOrderStatus");

    return dispatch({
      type: ACTION_TYPE.ORDER_APPROVE_CC_PAYMENT.FAILED,
      payload: { 
        error: errorMessage,
        shouldCheckOrderStatus,
        checkingOrderStatusDeadline: Date.now() + CHECKING_ORDER_STATUS_DELAY,
      },
    });
  }
};

export const rechargeCard = ({
  creditCard,
  customerDetails,
  paymentTypeDetails,
  currency,
  cvv,
  chargeMultiple,
  chargeCardBasePrice,
  discountPercentage = 0,
  isGiftCard = false,
  tokenResponse,
}) => async (dispatch, getState) => {
  const { user, order: { openPaymentTokens } } = getState();

  if (!chargeCardBasePrice) {
    return dispatch({
      type: ACTION_TYPE.RECHARGE_CARD.FAILED,
      payload: {
        error: "No charge card amount defined for restaurant",
      },
    });
  }
  const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
  if (
    !paymentTypeIdentifier ||
    !_.includes(SUPPORTED_PAYMENT_IDS, paymentTypeIdentifier)
  ) {
    const error = `trying to pay (using credit card) but with not supported paymentTypeIdentifier: ${paymentTypeIdentifier}`;
    console.error(error);
    return dispatch({
      type: ACTION_TYPE.RECHARGE_CARD.FAILED,
      payload: { error },
    });
  }

  dispatch({ type: ACTION_TYPE.RECHARGE_CARD.STARTED });

  const bagAPI = new BagAPI(user);
  const discountMultiplier = (100 - discountPercentage) / 100;
  const amount =
    chargeCardBasePrice.amount * (chargeMultiple || _.get(user.giftCardState, "giftCardAmount", 1)) * discountMultiplier;

  //open payment 
  const openPaymentResponse = paymentTypeIdentifier === PAYMENT_ID.TOAST_HOSTED ? openPaymentTokens : await openPayment({
    creditCard,
    user,
    paymentTypeIdentifier,
    currency,
    amount,
    customerDetails,
  });

  try {
    const confirmPaymentRequest = await dispatch(
      addPaymentMethodIfNeeded({
        creditCard,
        customerDetails,
        amount,
        user,
        currency,
        paymentTypeDetails,
        cvv,
        chargeCard: true,
        isGiftCard,
        tokenResponse,
        openPaymentResponse
      }),
    );

    const loadChargeCardResult = await bagAPI.confirmPayment(
      confirmPaymentRequest,
    );

    if (!loadChargeCardResult) {
      console.error(
        DEFAULT_ERROR,
        "No load charge card result for applyPayment",
      );
      return dispatch({
        type: ACTION_TYPE.RECHARGE_CARD.FAILED,
        payload: { error: DEFAULT_ERROR },
      });
    }
    if (loadChargeCardResult.wasConfirmed) {
      return dispatch({
        type: ACTION_TYPE.RECHARGE_CARD.SUCCESS,
      });
    }

    const error = DEFAULT_ERROR;
    return dispatch({
      type: ACTION_TYPE.RECHARGE_CARD.FAILED,
      payload: { error },
    });
  } catch (error) {
    const errorMessage =
      typeof error === "string" || !error.errorMessage
        ? error
        : error.errorMessage;
    console.error("IN CATCH", errorMessage);

    dispatch({
      type: ACTION_TYPE.RECHARGE_CARD.FAILED,
      payload: { error: errorMessage },
    });
  }
};

export const resetCheckPayment = () => ({ type: ACTION_TYPE.PAY_CHECK.RESET });

export const payCheck = ({
  creditCard,
  customerDetails,
  paymentTypeDetails,
  checkDetails,
  currency,
  cvv,
  isGiftCard = false,
  tokenResponse,
  batchIndex = -1,
  amountFromCreditCard,
  amountFromChargeCard,
  giftCardsToRedeem,
  tipAmount = 0,
  branchId
}) => async (dispatch, getState) => {
  console.log({
    amountFromCreditCard,
    amountFromChargeCard,
    giftCardsToRedeem,
  });

  const { user } = getState();

  if (!checkDetails) {
    return dispatch({
      type: ACTION_TYPE.PAY_CHECK.FAILED,
      payload: {
        error: "No check details for payment",
      },
    });
  }
  const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
  if (
    !paymentTypeIdentifier ||
    !_.includes(SUPPORTED_PAYMENT_IDS, paymentTypeIdentifier)
  ) {
    const error = `trying to pay (using credit card) but with not supported paymentTypeIdentifier: ${paymentTypeIdentifier}`;
    console.error(error);
    return dispatch({
      type: ACTION_TYPE.PAY_CHECK.FAILED,
      payload: { error },
    });
  }

  dispatch({ type: ACTION_TYPE.PAY_CHECK.STARTED, payload: { checkDetails } });
  const amount = amountFromCreditCard;
  const bagAPI = new BagAPI(user);

  try {
    // open Payment
    const openPaymentResponse = amountFromCreditCard > 0 && paymentTypeIdentifier === PAYMENT_ID.TOAST_HOSTED ? openPaymentTokens : await openPayment({
      creditCard,
      user,
      paymentTypeIdentifier,
      currency,
      amount,
      customerDetails,
      checkDetails,
      branchId
    });

    const otpPaymentRequest =
      amountFromCreditCard > 0 &&
      (await dispatch(
        addPaymentMethodIfNeeded({
          creditCard,
          customerDetails,
          amount,
          user,
          currency,
          paymentTypeDetails,
          cvv,
          isGiftCard,
          tokenResponse,
          checkDetails,
          openPaymentResponse,
          selectedBranchId: branchId
        }),
      ));

    const occiChargeAmount = amountFromChargeCard && {
      amount: amountFromChargeCard,
      currency,
    };

    const externalGiftCardPaymentRequests =
      !_.isEmpty(giftCardsToRedeem) &&
      _.map(
        giftCardsToRedeem,
        ({
          redeemAmount,
          cardNumber: giftCardNumber,
          cardPIN: giftCardPIN,
        }) => ({
          giftCardNumber,
          chargeAmount: { amount: redeemAmount, currency },
          giftCardPIN,
        }),
      );

    const payCheckResult = await bagAPI.payCheck({
      id: _.get(checkDetails, "check.id"),
      confirmPaymentRequest: {
        ...(otpPaymentRequest && { otpPaymentRequests: [otpPaymentRequest] }),
        ...(occiChargeAmount && { occiChargeAmount }),
        ...(externalGiftCardPaymentRequests && {
          externalGiftCardPaymentRequests,
        }),
      },
      batchIndex,
      couponInstanceIds: _.get(
        _.find(_.get(checkDetails, "couponBatches.batches"), { batchIndex }),
        "couponInstanceIds",
        [],
      ),
      purchaseEventId: _.get(checkDetails, "purchaseEvent.id"),
      tipAmount,
      // selectedTipOptionDetails,
    });


    if (!payCheckResult) {
      console.error(DEFAULT_ERROR, "No pay check result to applyPayment");
      return dispatch({
        type: ACTION_TYPE.PAY_CHECK.FAILED,
        payload: { error: DEFAULT_ERROR },
      });
    }
    if (payCheckResult) {
      return dispatch({
        type: ACTION_TYPE.PAY_CHECK.SUCCESS,
        payload: { checkDetails: payCheckResult },
      });
    }

    const error = DEFAULT_ERROR;
    return dispatch({
      type: ACTION_TYPE.PAY_CHECK.FAILED,
      payload: { error },
    });
  } catch (error) {
    const errorMessage =
      typeof error === "string" || !error.errorMessage
        ? error
        : error.errorMessage;
    console.error("IN CATCH", errorMessage);

    dispatch({
      type: ACTION_TYPE.PAY_CHECK.FAILED,
      payload: { error: errorMessage },
    });
  }
};

const GET_STATUS_WAIT_PERIOD_SECONDS = 10;

export const checkOrderStatus = () => async (dispatch, getState) => {
  const { user, order } = getState();

  const bagAPI = new BagAPI(user);
  const orderId = _.get(order, "placedOrderId");
  if (!orderId) {
    console.warn("trying to get order status without order id");
    return;
  }
  try {
    console.log(
      `Order is taking to long, and waiting ${GET_STATUS_WAIT_PERIOD_SECONDS} seconds before checking status...`,
    );
    dispatch({
      type: ACTION_TYPE.GET_ORDER_STATUS.STARTED,
      payload: { orderId },
    });
    // await sleep(GET_STATUS_WAIT_PERIOD_SECONDS * 1000);
    const checkoutResponse = await bagAPI.getOrderStatus(orderId);
    console.log(
      `checked order status returned: ${_.get(
        checkoutResponse,
        "status",
      )} for orderId: ${orderId}`,
    );
    dispatch({
      type: ACTION_TYPE.GET_ORDER_STATUS.SUCCESS,
      payload: { checkoutResponse },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: ACTION_TYPE.GET_ORDER_STATUS.FAILED, payload: { error } });
  }
};

export const addPromoCode = (promoCode) =>
  withTimestamp({
    type: ACTION_TYPE.ADD_PROMO_CODE,
    payload: { promoCodes: [{ code: promoCode }] },
  });

export const removePromoCode = (promoCode) =>
  withTimestamp({
    type: ACTION_TYPE.REMOVE_PROMO_CODE,
    payload: { promoCode },
  });

export const setSearchCode = (searchCode) => ({
  type: ACTION_TYPE.SET_SEARCH_CODE,
  payload: { searchCode },
});
export const reportOrderToPixels = (orderId) => ({
  type: ACTION_TYPE.REPORT_ORDER_TO_PIXELS,
  payload: { orderId },
});
export const setTableCode = (tableCode) =>
  withTimestamp({ type: ACTION_TYPE.SET_TABLE_CODE, payload: { tableCode } });

export const resetTableCode = (tableCode) =>
  withTimestamp({ type: ACTION_TYPE.RESET_TABLE_CODE });

export const setServingOptionMandatoryField = ({ field, value }) => ({
  type: ACTION_TYPE.SET_SERVING_OPTION_MANDATORY_FIELD,
  payload: { field, value },
});

export const fireOrderEvent = ({ type, details }) =>
  withTimestamp({
    type: ACTION_TYPE.FIRE_ORDER_EVENT,
    payload: { type, details, date: new Date() },
  });

export const setExecutePayment = (executePayment) => ({
  type: ACTION_TYPE.SET_EXECUTE_PAYMENT,
  payload: { executePayment }
});

export const syncOrderItems = (menuData) => (dispatch, getState) => {
  const { order: { orderItems } } = getState();

  const orderItemsData = orderItems.map(item => {
    const menuItemCategory = _.find(menuData, ({ id }) => id === item.categoryId)
    const menuItem = _.find(_.get(menuItemCategory, "items"), ({ id }) => id === item.sourceItem.id);

    // sizeOptions are coming from menuItems, which is not available here for now
    // const itemSelectorTitle = _.first(menuItemsOrderData[item.sourceItem.id].sizeOptions);

    return ({
      ..._.omit(item, "categoryId"),
      sourceItem: {
        ...menuItem,
        // heldItemsSelectionTitle: _.get(itemSelectorTitle, "heldItemsSelectionTitle"),
      }
  })})

  dispatch({
    type: ACTION_TYPE.SYNC_ORDER_ITEMS,
    payload: orderItemsData
  })
}

export const setFeaturedProductToByInOrder = (orderItem) => ({
  type: ACTION_TYPE.SET_FEATURED_ITEM_TO_BE_IN_ORDER,
  payload: { orderItem }
})

export const resetFeaturedProductToByInOrder = () => ({
  type: ACTION_TYPE.RESET_FEATURED_ITEM_TO_BE_IN_ORDER,
})
